import {languageMore} from "@/api/language";

export var reportStatus = {
    value0: languageMore(localStorage.getItem('languageIndex')).PENDING,
    value1: languageMore(localStorage.getItem('languageIndex')).PROCESSING,
    value2: languageMore(localStorage.getItem('languageIndex')).PROCESSED,
}
export var colorOptions= [
    {
        value: 'red',
        color: '#FE3A2F'
    },
    {
        value: 'orange',
        color: '#FF950F'
    },
    {
        value: 'yellow',
        color: '#FECB09'
    },
    {
        value: 'green',
        color: '#1CC759'
    },
    {
        value: 'cambridgeBlue', // 浅蓝
        color: '#51A9F1'
    },
    {
        value: 'darkBlue', // 深蓝
        color: '#0A79FE'
    },
    {
        value: 'purple',
        color: '#C075DB'
    },
    {
        value: 'brown',
        color: '#D8A69E'
    },
    {
        value: 'white',
        color: '#FFFFFF'
    },]

export var goodsTypeOptions = [
    {
        value:1,
        name:languageMore(localStorage.getItem('languageIndex')).DAILY_GOODS
    },
    {
        value:2,
        name:languageMore(localStorage.getItem('languageIndex')).SPECIAL_GOODS
    }
]
export function filterGoodsTypeOptions(status){
    return goodsTypeOptions.filter(item=>item.value==status)[0]?goodsTypeOptions.filter(item=>item.value==status)[0].name:languageMore(localStorage.getItem('languageIndex')).DAILY_GOODS
}

var consultTypeOptions = [
    {
        value:1,
        label:languageMore(localStorage.getItem('languageIndex')).HISTORY_INFORMATION

    },
    {

        value:2,
        label:languageMore(localStorage.getItem('languageIndex')).ORDER_INQUIRY_1
    },
    {
        value:3,
        label:languageMore(localStorage.getItem('languageIndex')).WAREHOUSE_CONSULTATION
    },
    {
        value:4,
        label:languageMore(localStorage.getItem('languageIndex')).OTHER_CONSULTATION
    },

]

var consultConfirmOptions = [
    {
        value:1,
        label:languageMore(localStorage.getItem('languageIndex')).READ_1
    },
    {
        value:2,
        label: languageMore(localStorage.getItem('languageIndex')).UNREAD
    }
]

export var pointOptions=[
    {
        value:0,
        feeDiscount:0,
        insuranceDiscount:0,
        addValueDiscount:0,
        point:0
    },
    {
        value:1,
        feeDiscount:1,
        insuranceDiscount:10,
        addValueDiscount:10,
        point:3000
    },
    {
        value:2,
        feeDiscount:2,
        insuranceDiscount:15,
        addValueDiscount:20,
        point:6000
    },
    {
        value:3,
        feeDiscount:3,
        insuranceDiscount:10,
        addValueDiscount:10,
        point:3000
    },
    {
        value:1,
        feeDiscount:1,
        insuranceDiscount:10,
        addValueDiscount:10,
        point:3000
    },
    {
        value:1,
        feeDiscount:1,
        insuranceDiscount:10,
        addValueDiscount:10,
        point:3000
    },
    {
        value:1,
        feeDiscount:1,
        insuranceDiscount:10,
        addValueDiscount:10,
        point:3000
    }
]

export function consultType(type){

    return consultTypeOptions.filter(item=>item.value==type)[0].label
}

export function consultConfirm(type){
    return consultConfirmOptions.filter(item=>item.value==type)[0].label
}

export var packageStatusOptions = [
    {
        value:0,
        name:languageMore(localStorage.getItem('languageIndex')).AWAITING_PAYMENT
    },
    {
        value:1,
        name:languageMore(localStorage.getItem('languageIndex')).PAID
    },
    {
        value:2,
        name:languageMore(localStorage.getItem('languageIndex')).WAIT_PACK
    },
    {
        value:3,
        name:languageMore(localStorage.getItem('languageIndex')).PACKED
    },
    {
        value:4,
        name:languageMore(localStorage.getItem('languageIndex')).READY_SHIP
    },
    {
        value:5,
        name:languageMore(localStorage.getItem('languageIndex')).SHIPPING_NOW
    },
    {
        value:6,
        name:languageMore(localStorage.getItem('languageIndex')).AWAIT_CUSTOMS

    },
    {
        value:7,
        name:languageMore(localStorage.getItem('languageIndex')).ENROUTE_INTL
    },
    {
        value:8,
        name:languageMore(localStorage.getItem('languageIndex')).ARRIVED_CNTRY
    },
    {
        value:9,
        name:languageMore(localStorage.getItem('languageIndex')).DOMESTIC_TRNSP
    },
    {
        value:10,
        name:languageMore(localStorage.getItem('languageIndex')).DELIVERED
    },
    {
        value:11,
        name:languageMore(localStorage.getItem('languageIndex')).ADDR_DELETED
    },
]

export var  orderStatusArr=[
    {
        code: 0,
        status: languageMore(localStorage.getItem('languageIndex')).AWAITING_PAYMENT,
        tip1:languageMore(localStorage.getItem('languageIndex')).PAYMENT_PENDING_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).PAYMENT_PENDING_TIP_2,
    },
    {
        code: 1,
        status: languageMore(localStorage.getItem('languageIndex')).PAYMENT_COMPLETED,
        tip1:languageMore(localStorage.getItem('languageIndex')).PAID_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).PAID_TIP_2,
    },
    {
        code: 2,
        status: languageMore(localStorage.getItem('languageIndex')).PENDING_ORDERS,
        tip1:languageMore(localStorage.getItem('languageIndex')).PURCHASING_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).PURCHASING_TIP_2
    },
    {
        code: 3,
        status: languageMore(localStorage.getItem('languageIndex')).AWAITING_CONFIRMATION,
        tip1:languageMore(localStorage.getItem('languageIndex')).AWAITING_CONFIRMATION,
        tip2:languageMore(localStorage.getItem('languageIndex')).AWAITING_CONFIRMATION
    },
    {
        code: 4,
        status: languageMore(localStorage.getItem('languageIndex')).SHIPPED_ORDERS,
        tip1:languageMore(localStorage.getItem('languageIndex')).SHIPPED_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).SHIPPED_TIP_2
    },
    {
        code: 5,
        status: languageMore(localStorage.getItem('languageIndex')).DELIVERED_ORDERS,
        tip1:languageMore(localStorage.getItem('languageIndex')).DELIVERED_ORDERS,
        tip2:languageMore(localStorage.getItem('languageIndex')).DELIVERED_ORDERS
    },
    {
        code: 6,
        status: languageMore(localStorage.getItem('languageIndex')).CANCEL_ORDER_ACTION,
        tip1:languageMore(localStorage.getItem('languageIndex')).CANCELED_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).CANCELED_TIP_2

    },
    {
        code: 7,
        status: languageMore(localStorage.getItem('languageIndex')).CANCEL_PAYMENT,
        tip1:languageMore(localStorage.getItem('languageIndex')).CANCELED_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).CANCELED_TIP_2
    },
    {
        code: 8,
        status: languageMore(localStorage.getItem('languageIndex')).IN_PACKING,
        tip1:languageMore(localStorage.getItem('languageIndex')).IN_PACKING_CENTER_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).IN_PACKING_CENTER_TIP_2,
    },
    {
        code: 9,
        status: languageMore(localStorage.getItem('languageIndex')).ORDERED,
        tip1: languageMore(localStorage.getItem('languageIndex')).PURCHASED_TIP_1,
        tip2: languageMore(localStorage.getItem('languageIndex')).PURCHASED_TIP_2,
    },
    {
        code: 10,
        status: languageMore(localStorage.getItem('languageIndex')).SHIPPED
    },
    {
        code: 11,
        status: languageMore(localStorage.getItem('languageIndex')).SHIPPED_STATUS
    },
    {
        code: 12,
        status: languageMore(localStorage.getItem('languageIndex')).SIGNED
    },
    {
        code:13,
        status: languageMore(localStorage.getItem('languageIndex')).COMPLETED,
        tip1:languageMore(localStorage.getItem('languageIndex')).COMPLETED,
        tip2:languageMore(localStorage.getItem('languageIndex')).COMPLETED,
    },
    {
        code:14,
        status:languageMore(localStorage.getItem('languageIndex')).AFTERSALES,
        tip1:languageMore(localStorage.getItem('languageIndex')).IN_AFTERSALES_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).IN_AFTERSALES_2
    },
    {
        code:15,
        status:languageMore(localStorage.getItem('languageIndex')).REFUNDED2,
        tip1:languageMore(localStorage.getItem('languageIndex')).REFUNDED_TIP_1,
        tip2:languageMore(localStorage.getItem('languageIndex')).REFUNDED_TIP_2,
    }
]

export function filterOrder(status){
    return orderStatusArr.filter(item=>item.code==status)[0]
}

export var serverStatusArr = [
    {
        name:languageMore(localStorage.getItem('languageIndex')).PENDING_PAYMENT,
        value:0,
        operation:languageMore(localStorage.getItem('languageIndex')).EDIT_AUTH
        ,
    },
    {
        name:languageMore(localStorage.getItem('languageIndex')).PAID1,
        value:1,
        operation:languageMore(localStorage.getItem('languageIndex')).VIEW
    },
    {
        name:languageMore(localStorage.getItem('languageIndex')).CANCELLED_STATUS,
        value: 2,
        operation:languageMore(localStorage.getItem('languageIndex')).VIEW
    }
]
export var refundReasonOptions=
    [
    {
        value:'NO_WANT',
        content:languageMore(localStorage.getItem('languageIndex')).NO_LONGER
    },
    {
        value:'PRICE_CHANGE',
        content:languageMore(localStorage.getItem('languageIndex')).PRICE_CHANGE
    },
    {
        value:'OUT_OF_STOCK',
        content:languageMore(localStorage.getItem('languageIndex')).OUT_OF_STOCK
    },
    {
        value:'WARNNING_SIZE',
        content:languageMore(localStorage.getItem('languageIndex')).WRONG_SIZE
    },
    {
        value:'ALWAYS_NO_SHIPPING',
        content:languageMore(localStorage.getItem('languageIndex')).LATE_DELIVERY
    },
    {
        value:'QUANTITY_QUESTION',
        content:languageMore(localStorage.getItem('languageIndex')).QUALITY_ISSUES
    },
    {
        value:'NOT_AS_DESCRIBE',
        content:languageMore(localStorage.getItem('languageIndex')).NOT_SAME
    },
    {
        value:'RECEIVE_WARN_PRODUCT',
        content:languageMore(localStorage.getItem('languageIndex')).INCORRECT
    },
    {
        value:'OTHER_REASON',
        content:languageMore(localStorage.getItem('languageIndex')).Others

    },

]
export function filterOrderStatus(status){
    return orderStatusArr.filter(item=>item.code==status)[0].status
}

export function filterPackageStatus(status){
    return packageStatusOptions.filter(item=>item.value==status)[0].name
}

export function filterGoodsType(goodsType){
    return goodsTypeOptions.filter(item=>goodsType==item.value)[0].name
}

export function filterServerStatus(status){
    return {
        name:serverStatusArr.filter(item=>status==item.value)[0]?serverStatusArr.filter(item=>status==item.value)[0].name:'未找到',
        operation:serverStatusArr.filter(item=>status==item.value)[0]?serverStatusArr.filter(item=>status==item.value)[0].operation:'未找到'
    }
}
export function filterColor(color) {
    var colorName = ''
    colorOptions.filter(item=>{
        if(color==item.value){
            colorName = item.color
        }
    })
    return colorName
}
export function filterReportStatus(num) {
    return reportStatus['value' + num]
}